import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import UnityWebViewer from "./components/UnityWebViewer";
// import SendMessageButton from "./components/SendMessageButton";
import ExperiencePage from "./components/ExperiencePage";
import HomePage from "./components/HomePage";



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/experiences/:experienceId" element={<ExperiencePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
