import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import AWS from "aws-sdk";
import axios from 'axios';



const  VoiceRecorder = forwardRef(({ doneRecording, assessmentComplete }, ref) => {
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const silenceTimerRef = useRef(null);
  const [fileName, setFileName] = useState("Test");

  const [fileToUpload, setFileToUpload] = useState(null);


  useImperativeHandle(ref, () => ({
        startRecording,
        uploadFileToS3
    }));

  const startRecording = async (assessmentId) => {

    setFileName(`${assessmentId}.webm`);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContextRef.current.createMediaStreamSource(stream);
    analyserRef.current = audioContextRef.current.createAnalyser();
    source.connect(analyserRef.current);

    // const options = { mimeType: "audio/wav" };
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };
    mediaRecorderRef.current.start();
    setRecording(true);
    // monitorSilence();
  };

  useEffect(() => {
    if(recording){
        // monitorSilence()
    }
   
  }, [recording])

  const monitorSilence = () => {
    const analyser = analyserRef.current;
    const dataArray = new Uint8Array(analyser.fftSize);
    const checkSilence = () => {
      analyser.getByteTimeDomainData(dataArray);
      const sum = dataArray.reduce(
        (acc, value) => acc + (value - 128) * (value - 128),
        0
      );
      const average = Math.sqrt(sum / dataArray.length) / 128;
      console.log(average)
      if (average < 0.01) {
        if (silenceTimerRef.current === null) {
          silenceTimerRef.current = setTimeout(() => {
            console.log("stop record")
            stopRecording();
          }, 3000);
        }
      } else {
        console.log('clearing')
        clearTimeout(silenceTimerRef.current);
        silenceTimerRef.current = null;
      }
    };
    const intervalId = setInterval(checkSilence, 100);
    mediaRecorderRef.current.onstop = () => clearInterval(intervalId);
  };

  const stopRecording = () => {

    console.log("STOP recording called")
    mediaRecorderRef.current.stop();
    setRecording(false);

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      audioChunksRef.current = [];

      // Prepare file for upload
      const file = new File([audioBlob], fileName, {
        type: "audio/webm",
      });

      setFileToUpload(file);
      doneRecording();
     
      //uploadFileToS3(presignedUrl, file);
      
    };
  };


  const uploadFileToS3 = async (presignedUrl) => {
    try {
        const response = await axios.put(presignedUrl, fileToUpload, {
            headers: {
                'Content-Type': "audio/webm" // Set to the appropriate MIME type of the file
            }
        });
        
        if (response.status === 200) {
            assessmentComplete();
            console.log('File uploaded successfully');
        } else {
            console.error('File upload failed:', response.data);
        }
    } catch (error) {
        console.error('Error uploading file:', error.response ? error.response.data : error.message);
    }
}

  return (
    <div className="record-button">
     
      <button  onClick={stopRecording} disabled={!recording}>
        Stop Recording
      </button>
    
      {/* {audioUrl && <audio src={audioUrl} controls />} */}
    </div>
  );
});

export default VoiceRecorder;


